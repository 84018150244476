import { extendTheme } from '@chakra-ui/react';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    primary: '#F58F00',
    secondary: '#2F1B00',
    brand: {
      100: '#F58F001a',
      200: '#F58F0002',
      300: '#F58F0003',
      400: '#F58F001A',
      500: '#F58F00',
      600: '#F58F008f',
      700: '#F58F00',
      800: '#F58F00',
      900: '#F58F00',
    },
    icon: '#BABDC1',
  },
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
  },
});

export default theme;
